import React, {Component} from 'react';

import Carousel from "react-multi-carousel";
import './style.css'
import axios from "axios";
import {Modal, ModalBody,Button} from "reactstrap";
import InformacionDesafio from "./informacionDesafio";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 4
    },
    desktop: {
        breakpoint: {max: 6000, min: 1024},
        items: 5
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 2
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};

class Desafios extends Component {
    constructor() {
        super();
        this.state={
            data:[],
            desafio:[],
            modal:false
        };
        this.handdleInfoDesafio = this.handdleInfoDesafio.bind(this);
    }

  async componentDidMount() {
    await axios.get(`${process.env.REACT_APP_API_STREAMING}/getdesafios`)
    .then((data) => this.setState({data:data.data.data}))
    .catch((error) => console.log('[ERROR]: Desafios', error))
    }

    handdleInfoDesafio(data){
        let {modal} = this.state;
        this.setState({desafio:data, toggle:modal, modal:!modal})
    }
    render() {
        let {desafio,modal,data} = this.state
        return (
            // <div className="mt-lg-5 mb--90 pt--120 pt_md--80 pt_sm--80" id="desafios" >
            <div className="pt--100 mb--20 " id="desafios" >
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center mb--80" data-aos="zoom-out-up" data-aos-delay="50"
                             data-aos-duration="1000">
                            <h2> <span className="theme-color">DESAFÍOS</span></h2>

                        </div>
                        <div style={{marginLeft: '20px', marginRight: '20px'}}>
                            <Carousel responsive={responsive}
                                      infinite={true}
                                      style={{padding: '15px'}}>

                                {data.map((item, index) => (
                                    <div className="flip-card2" key={index}  data-aos="zoom-in-down" data-aos-duration="2000">
                                        <div className="flip-card-inner2">
                                            <div className="flip-card-front">
                                                <div style={{height: '66,3%'}}>
                                                    <div>
                                                        <img className="card-img-top "
                                                             src={item.mainPhoto}
                                                             width={170}
                                                             height={190}
                                                             alt="Card  cap"/>
                                                    </div>
                                                </div>
                                                <div id="contenedorTituloDesafios" >
                                                    <span
                                                        style={{
                                                            fontSize:'13px',
                                                            fontWeight:'bold'
                                                        }}>{item.title}</span>
                                                    <br/>

                                                </div>
                                                <div id="contendorImagenPatrocinado" style={{}}>
                                                    <div className="row">
                                                        <div className="col-12" style={{display: 'flex'}}>
                                                            <div style={{alignSelf: 'center'}}>
                                                                <span style={{fontWeight: '800', fontSize: '14px'}}>Patrocinado:</span>
                                                            </div>
                                                            <div >
                                                                <img  src={item.sponsorPhoto}
                                                                      width="80"
                                                                      height="75"
                                                                      style={{borderRadius:'100px', marginLeft:'4px'}}
                                                                      alt=" sponsorPhoto"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                               <div style={{textAlign:'end', marginRight:'20px'}}>
                                                   <Button className="button-olive"  size='sm' onClick={()=> this.handdleInfoDesafio(item)}>EXPLORAR</Button>

                                               </div>
                                            </div>

                                        </div>
                                    </div>
                                ))}


                            </Carousel>
                        </div>
                    </div>
                </div>
                <Modal isOpen={modal} toggle={this.handdleInfoDesafio} className="modalBlog" >
                    <div style={{textAlign: 'end',     marginRight: '10px', marginTop: '10px'}}>
                        <IconButton aria-label="fingerprint" color="secondary" onClick={() => this.setState({modal:false})}>
                            <CloseIcon style={{color: 'grey',}} fontSize={"medium"}/>
                        </IconButton>
                    </div>
                    <ModalBody style={{marginTop:'-px'}}>
                       <InformacionDesafio data={desafio} />
                    </ModalBody>

                </Modal>
            </div>
        );
    }
}

export default Desafios;